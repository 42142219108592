<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>HAK Diatas HAK </strong><small>Detail Data</small>            
            <a href="/#/hakdiatashak/list" class="float-right btn btn-secondary form-control-sm">Kembali</a>
            <!-- <a :href="linkedit" class="float-right btn btn-warning form-control-sm" style='margin-right:10px'>Edit Data</a> -->
            <!-- <a href="/#/asetwilayah/add" class="float-right btn btn-success form-control-sm" style='margin-right:10px'>Tambah</a> -->
            
          </CCardHeader>
          <CCardBody>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td style="width:20%">NO PERJANJIAN LAMA</td>
                  <td style="width:30%">: <strong>{{no_perjanjian_lama}}</strong></td>
                  <td style="width:20%">TGL PERJANJIAN LAMA</td>
                  <td style="width:30%">: <strong>{{tgl_perjanjian_lama}}</strong></td>
                </tr> 
                <tr>
                  <td>NO PERJANJIAN BARU</td>
                  <td>: <strong>{{no_perjanjian_baru}}</strong></td>
                  <td>TGL PERJANJIAN BARU</td>
                  <td>: <strong>{{tgl_perjanjian_baru}}</strong></td>
                </tr>
                <tr>
                  <td>NO PERJANJIAN KERJASAMA</td>
                  <td>: <strong>{{no_perjanjian_kerjasama}}</strong></td>
                  <td>TGL PERJANJIAN KERJASAMA</td>
                  <td>: <strong>{{tgl_perjanjian_kerjasama}}</strong></td>
                </tr>
                <tr>
                  <td>TIPE HAK</td>
                  <td>: <strong>{{jenis_hak}}</strong></td>
                  <td>NO HAK</td>
                  <td>: <strong>{{no_hak}}</strong></td>
                </tr>               
                <tr>
                  <td>NAMA PEMILIK</td>
                  <td>: <strong>{{pemilik}}</strong></td>
                  <td>SU</td>
                  <td>: <strong>{{su}}</strong></td>
                </tr>
                <tr>
                  <td>NIB</td>
                  <td>: <strong>{{nib}}</strong></td>
                  <td>TANGGAL SERTIFIKAT</td>
                  <td>: <strong>{{tgl_sertifikat}}</strong></td>
                </tr>
                <tr>
                  <td>LUAS</td>
                  <td>: <strong>{{luas}}</strong></td>
                  <td>TGL BERAKHIRNYA HAK</td>
                  <td>: <strong>{{tgl_berakhir_hak}}</strong></td>
                </tr>
                <tr>
                  <td>TGL PERPANJANGAN HAK</td>
                  <td>: <strong>{{tgl_perpanjang_hak}}</strong></td>
                  <td>TGL PENYETORAN</td>
                  <td>: <strong>{{tgl_penyetoran}}</strong></td>
                </tr>
                <tr>
                  <td>NO STS</td>
                  <td>: <strong>{{no_sts}}</strong></td>
                  <td>ALAMAT</td>
                  <td>: <strong>{{alamat_hak}}</strong></td>
                </tr>
                <tr>
                  <td>KATEGORI</td>
                  <td>: <strong>{{kategori_hak}}</strong></td>
                  <!-- <td>TANGGAL SERTIFIKAT</td>
                  <td>: <strong>{{tgl_sertifikat}}</strong></td> -->
                </tr>
              </tbody>
            </table>
            
            <!-- <label><b>FOTO ASET </b></label>                  -->
            <!-- <table class="table table-striped">
              <tr>
                <td>                  
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Foto Lokasi </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="sertifikat" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Sertifikat </b> <a href='' target='_blank'>[Download]</a></label>
                  </div>
                </td>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;">
                    <img :src="buku_tanah" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Buku Tanah </b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
              </tr><br/>
              <tr>
                <td>
                  <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="alas_hak" class="img-fluid" style="height: 100%;width: 100%;" />
                    <label><b>Alas Hak</b> <a href='' target='_blank'>[Download]</a></label> 
                  </div>
                </td>
                <td> -->
                  <!-- <div class="form-group" style="height: 200px;width: 100%;" >
                    <img :src="bangunan" class="img-fluid" style="height: 100%;width: 100%;" />
                  </div> -->
                <!-- </td>
                <td></td>
              </tr>
            </table> -->
            <!-- <div class="form-group">
              <label> File Buku Tanah <a href='' target='_blank'>[Download]</a></label>
              <br />                  
              <div class="custom-file">
                <input type="file" class="custom-file-input" @change="onFileChange2($event)" id="inputGroupFile02"
                :placeholder="placeholder" aria-describedby="inputGroupFileAddon01">
                <label class="custom-file-label" for="inputGroupFile01">{{placeholder}}</label>
              </div>              
            </div> -->
            <!-- <div class="form-group" style="height: 400px;width: 100%;" >
              <label><b>Foto Aset </b></label><br/>                  
              <img :src="foto_lokasi" class="img-fluid" style="height: 100%;width: 100%;" />
            </div> -->
            
            <div style="height: 400px;width: 100%;" >
            <l-map ref="mapElement" :zoom="zoom" :center="center" style='height: 100%;width: 100%;'>
              <l-tile-layer :url="url"></l-tile-layer>
              <l-geo-json ref="track" :geojson="geojsonFile"  ></l-geo-json>
              <!-- <l-polygon :lat-lngs="latlngs" color="red"></l-polygon> -->
            </l-map>
            </div><br>
    </CCardBody>
  </CCard> 

      <!-- <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Data HAK diatas HAK</h4> 
          </slot>
        </CCardHeader>
        <CCardBody> -->

        <!-- <table class="table  table-striped" id="datatable">
          <thead class="bg-info">
            <tr>
              <th>No </th>
              <th>Sub HAK </th>
              <th>Kode Barang </th>
              <th>Nama Barang </th>
              <th>Kota / Kabupaten </th>
              <th>Nama Instansi</th>
              <th style="width:10%">No Hak </th>
              
              <th>Tanggal Perolehan </th>
              <th>Harga Perolehan</th>
              
            </tr>
          </thead>
          
          <tbody></tbody>
        </table> -->
        
          <!-- </CCardBody>
        </CCard> -->
      </CCol>
    </CRow>

    <CModal
      :show.sync="myerror"
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<style scoped>
  .leaflet-control-attribution a{
    display: none !important
  }
</style>
<script>

import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons';
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'
import { LMap, LTileLayer, LPolygon, LMarker, LGeoJson } from 'vue2-leaflet';

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LGeoJson
  },
  data () {
    return {
      linkedit :"/#/asetwilayah/edit/"+this.$route.params.id_aset_bersertifikat,
      placeholder :"",
      latlngs: [[109.290056324599,-0.0124423568214555],[109.29122650719,-0.0160182734285108],[109.291621915063,-0.0158496126787141],[109.29045346265801,-0.0122778678589834],[109.290056324599,-0.0124423568214555]],
      no_perjanjian_lama : "",
      tgl_perjanjian_lama : "",
      no_perjanjian_baru : "",
      tgl_perjanjian_baru : "",
      no_perjanjian_kerjasama : "",
      tgl_perjanjian_kerjasama : "",
      jenis_hak : "",
      no_hak : "",
      pemilik : "",
      su : "",
      nib : "",
      tgl_sertifikat : "",
      luas : "",
      tgl_berakhir_hak : "",
      tgl_perpanjang_hak : "",
      tgl_penyetoran : "",
      no_sts : "",
      alamat_hak : "",
      kategori_hak : "",
      file : "",
      filename : "",
      myerror : false,
      placeholder : "Pilih File ",
      selected: [], // Must be an array reference!
      ruangans: [],
      lemaris: [],
      koloms: [],
      bariss: [],
      show: true,
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
      zoom: 14,
      center: [-0.0353948,109.2615099],
      centerGeojson: [],
      coordinates:[],
      details:{},
      geojsonFile : {}
    }
  },
  mounted(){
    this.showHakdiatashak();
  // this.loadData();
    this.details=JSON.parse(this.$store.state.properties);
    // var koor = JSON.parse(this.$store.state.coordinates);
    // var koor2=JSON.stringify(koor.substring( 3, koor.length - 3 ));
    // this.coordinates = koor2;
    // this.center=this.findCenter(koor2);
    console.log("koor "+(this.$store.state.coordinates));
    this.geojsonFile = {
        "type":"FeatureCollection", 
        "features": 
          [
            {
            "type":"Feature",
            "geometry":{
                "type":"Polygon",
                "coordinates":JSON.parse(this.$store.state.coordinates)
            },
            "properties":null
            }
          ]
    };

    var newKoor = JSON.parse(this.$store.state.coordinates);
    // alert(newKoor[0][0]);
    var cntr = [newKoor[0][0][0],newKoor[0][0][1]];
    this.centerGeojson.push(newKoor[0][0][1]);
    this.centerGeojson.push(newKoor[0][0][0]);
    // alert(cntr);

    this.$refs.mapElement.mapObject.panTo(this.centerGeojson);
   
    // alert(JSON.stringify(this.geojsonFile));
    //After building your geoJson layers, just call this:
  // this.$nextTick().then(() => {
  //     var group = new featureGroup();

  //     this.$refs.mapElement.mapObject.eachLayer(function(layer) {
  //         if (layer.feature != undefined)
  //             group.addLayer(layer);
  //     });

  //     this.$refs.mapElement.mapObject.fitBounds(group.getBounds(), { padding: [20, 20] });
  // });
    // var mymap = L.map(this.$refs['mapElement']).setView([51.505, -0.09], 13);
    // document.getElementsByClassName('leaflet-control-attribution').style.display = 'none';
    // map.attributionControl.setPrefix(false);
    // L.map('map', {attributionControl: false})
  },

  methods: {
    // toggle(id, koor, prop) {
      
    //   this.$store.commit('setProp', prop);
    //   this.$store.commit('setCoord', koor);
    //   this.$router.push("/asetwilayah/edit/"+id);
    // },
    doSomethingOnReady() {
      this.track = this.$refs.track.mapObject;
      this.map.fitBounds(this.track.getBounds(), {maxZoom: 13});
      console.log("Bounds:", this.track.getBounds());
    },
    findCenter:function(markers) {
      let lat = 0;
      let lng = 0;
      
      for(let i = 0; i < markers.length; ++i) {
          lat += markers[i].lat;
          lng += markers[i].lng;
      }

      lat /= markers.length;
      lng /= markers.length;

      return [lat,lng]
  },
    loadData: function(event){

      axios.get(process.env.VUE_APP_BASE_URL+"raw_ruangan")
        .then((response) => {
              this.ruangans=response.data;
        });
      axios.get(process.env.VUE_APP_BASE_URL+"raw_lemari")
        .then((response) => {
              this.lemaris=response.data;
        });
      axios.get(process.env.VUE_APP_BASE_URL+"raw_kolom")
        .then((response) => {
              this.koloms=response.data;
        });
      axios.get(process.env.VUE_APP_BASE_URL+"raw_baris")
        .then((response) => {
              this.bariss=response.data;
        });

    },
    onFileChange2(event) {
      var fileData = event.target.files[0];
      this.filename = fileData.name;
      this.file = fileData;
      this.placeholder = fileData.name;
      // alert(fileData.name);
    },        
    showHakdiatashak: function(event){
      // Simple POST request with a JSON body using axios
      const asetwilayah = { 
                          no_perjanjian_lama: this.no_perjanjian_lama, 
                          tgl_perjanjian_lama: this.tgl_perjanjian_lama,
                          no_perjanjian_baru: this.no_perjanjian_baru,
                          tgl_perjanjian_baru: this.tgl_perjanjian_baru,
                          no_perjanjian_kerjasama: this.no_perjanjian_kerjasama,
                          tgl_perjanjian_kerjasama: this.tgl_perjanjian_kerjasama,
                          jenis_hak: this.jenis_hak,
                          no_hak: this.no_hak,
                          pemilik: this.pemilik,
                          su: this.su,
                          nib: this.nib,
                          tgl_sertifikat: this.tgl_sertifikat,
                          luas: this.luas,
                          tgl_berakhir_hak: this.tgl_berakhir_hak,
                          tgl_perpanjang_hak: this.tgl_perpanjang_hak,
                          tgl_penyetoran: this.tgl_penyetoran,
                          no_sts: this.no_sts,
                          alamat_hak: this.alamat_hak,
                          kategori_hak: this.kategori_hak
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"detailhakdiatashak/"+this.$route.params.id_hak_diatas_hak)
        .then((response) => {
            
              this.no_perjanjian_lama=response.data.no_perjanjian_lama;
              this.tgl_perjanjian_lama=response.data.tgl_perjanjian_lama;
              this.no_perjanjian_baru=response.data.no_perjanjian_baru;
              this.tgl_perjanjian_baru=response.data.tgl_perjanjian_baru;
              this.no_perjanjian_kerjasama=response.data.no_perjanjian_kerjasama;
              this.tgl_perjanjian_kerjasama=response.data.tgl_perjanjian_kerjasama;
              this.jenis_hak=response.data.jenis_hak;
              this.no_hak=response.data.no_hak;
              this.pemilik=response.data.pemilik;
              this.su=response.data.su;
              this.nib=response.data.nib;
              this.tgl_sertifikat=response.data.tgl_sertifikat;  
              this.luas=response.data.luas;       
              this.tgl_berakhir_hak=response.data.tgl_berakhir_hak; 

              this.tgl_perpanjang_hak=response.data.tgl_perpanjang_hak;       
              this.tgl_penyetoran=response.data.tgl_penyetoran;       
              this.no_sts=response.data.no_sts;       
              this.alamat_hak=response.data.alamat_hak;       
              this.kategori_hak=response.data.kategori_hak;       
              // this.plang_tanah=response.data.plang_tanah;       
              // this.patok_batas=response.data.patok_batas;       
              // this.pagar=response.data.pagar;       
            var newKoor = jsonpeta.geometry.coordinates;
    // alert(newKoor[0][0]);
    var cntr = [newKoor[0][0][0],newKoor[0][0][1]];
    this.centerGeojson.push(newKoor[0][0][0][0].substring(-1));
    // this.centerGeojson.push(newKoor[0][0][0]);
    // alert(this.centerGeojson);

    this.$refs.mapElement.mapObject.panTo(this.centerGeojson);     
        })
    }
    
  }
}
</script>